/**
 * 定向包api接口统一管理
 */
import { get, post } from '../https.js'
 

   /**
  * 定向包地域查询
  * @param {*} data
  * @returns
  */
export const selectRegion = (data) => get('/Directional/selectRegion', data)
    
   /**
  * 定向包定点类型（定向包-Adq）
  * @param {*} data
  * @returns
  */
export const selectAdqRegionType = (data) => get('/mediaDictonary/selectAdqRegionType', data)

   /**
  * 添加定向包（Adq添加定向包）
  * @param {*} data
  * @returns
  */
    export const insertDirectionalPage = (data) => post('/Directional/insertDirectionalPage', data)
    
  
       /**
  * 定向包查询（Adq定定向包查询)
  * @param {*} data
  * @returns
  */
export const selectDirectional = (data) => get('/Directional/selectDirectional', data)
  
       /**
  * 定向包删除（Adq定定向包删除
  * @param {*} data
  * @returns
  */
export const deleteDirectional = (data) => get('/Directional/deleteDirectional', data)
    